




















































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { sortBy as _sortBy, cloneDeep as _cloneDeep } from 'lodash';
import * as generators from '@/shared/helpers/generators.helper';
import { localeDate } from '@/shared/filters/date/date.filter';
import * as toasts from '@/shared/configs/toasts/toasts.config';
import SubscriptionTier from '@/shared/models/subscriptions/SubscriptionTier.model';
import PromoCode from '@/shared/models/promo/PromoCode.model';

export default Vue.extend({

  name: 'Purchase',

  data () {
    return {
      pendingComponentInit: false,
      pendingRequest: false,
      pendingPayment: false,
      pendingPromoCodeValidation: false,
      paypalButtonsRendered: false,
      purchaseStep: 1,
      stepNames: ['Συνδρομή', 'Πληρωμή', 'Ολοκλήρωση'],
      promoCode: '',
      promoCodeId: '',
      promoCodeErrorText: '',
      subscriptionTiers: [] as SubscriptionTier[],
      selectedPaymentOption: -1,
      showCreditCardPurchaseHowTo: false,
      paymentCompleted: false,
      localeDate: localeDate
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      hasActiveSubscription: 'hasActiveSubscription'
    }),
    ...mapGetters('subscription/tiers', {
      tiers: 'tiers'
    }),
    ...mapGetters('purchase', {
      purchase: 'editedPurchase'
    }),

    sortedTiers (): SubscriptionTier[] { return _sortBy(this.subscriptionTiers, 'months'); }
  },

  created () {
    this.initComponent();
  },

  mounted () {
    const script = document.createElement('script');
    script.src =
      `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=EUR&locale=el_GR`;
    document.body.appendChild(script);
  },

  methods: {
    // ================================================================================
    // Events
    // ================================================================================

    onGoToStep (step: number): void {
      this.purchaseStep = step;
    },

    onSubscriptionTierSelected (index: number): void {
      if (this.purchaseStep !== 1) { return; }

      const tier: SubscriptionTier = this.sortedTiers[index];
      this.purchase.months = tier.months;
      this.purchase.price = tier.price;
      this.purchaseStep = 2;
      this.paypalButtonsRendered = false;
    },

    async onPromoCodeSubmit (): Promise<void> {
      if (this.pendingPromoCodeValidation) { return; }
      // Code is empty
      if (!this.promoCode.trim()) { this.subscriptionTiers = _cloneDeep(this.tiers); }

      try {
        this.pendingPromoCodeValidation = true;
        this.promoCodeErrorText = '';
        const promoCode: PromoCode = await this.$store.dispatch('promo/codes/fetchPromoCode', this.promoCode);
        this.promoCodeId = promoCode.id || '';

        const tiers: SubscriptionTier[] = _cloneDeep(this.tiers);
        tiers.forEach(tier => {
          if (promoCode.extra_months) { tier.months += promoCode.extra_months; }
          if (promoCode.price_discount) { tier.price = Math.ceil(tier.price * (1 - (promoCode.price_discount / 100))); }
        });
        this.subscriptionTiers = tiers;
      } catch (e) {
        // Code is invalid
        if (e.message && e.message === 'promo-code-not-found') {
          this.promoCodeErrorText = toasts.messages.promoCodes.error.notFound;
          this.subscriptionTiers = _cloneDeep(this.tiers);
        }
      } finally {
        this.pendingPromoCodeValidation = false;
      }
    },

    onPaymentOptionSelected (option: number): void {
      if (!option) { return; }
      this.paymentCompleted = false;
      this.purchase.transfer_id = '';
      this.purchase.paid_with = '';
      switch (option) {
        case 1:
          this.purchase.paid_with = 'paypal';
          this.$nextTick(() => this.renderPaypalButtons());
          break;
        case 2:
          this.purchase.paid_with = 'χρεωστική κάρτα';
          this.$nextTick(() => this.renderPaypalButtons());
          break;
        case 3:
          this.paypalButtonsRendered = false;
          this.purchase.paid_with = 'κατάθεση';
          this.purchase.transfer_id = `MAR${generators.randomInteger(1000, 9999)}`;
          this.paymentCompleted = true;
          break;
      }
    },

    async onPaymentSubmitted (): Promise<void> {
      if (!this.purchase) { return; }
      try {
        this.pendingPayment = true;
        await this.$store.dispatch('purchase/createPurchase', this.purchase);
        if (this.promoCodeId.trim()) { await this.$store.dispatch('promo/codes/addPromoCodeUse', this.promoCodeId); }
        this.onGoToStep(3);
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingPayment = false;
      }
    },

    // ================================================================================
    // Helpers
    // ================================================================================

    getTierPriceDifference (index: number, type: string): number {
      const tier = this.sortedTiers[index];
      if (!tier) { return 0; }
      const highestPrice = this.sortedTiers[0].price * tier.months;
      const currentPrice = tier.price;
      return type === 'percentage'
        ? Math.ceil((highestPrice / currentPrice - 1) * 100)
        : Math.ceil(highestPrice - currentPrice);
    },

    renderPaypalButtons () {
      if (this.paypalButtonsRendered) { return; }
      // eslint-disable-next-line
      // @ts-ignore
      window.paypal
        .Buttons({
          createOrder: (data: any, actions: any): any => {
            const description = `${this.purchase?.months} ${this.purchase?.months === 1 ? 'μήνας' : 'μήνες'}`;
            return actions.order.create({
              purchase_units: [
                {
                  description: description,
                  amount: {
                    currency_code: 'EUR',
                    value: this.purchase?.price
                  }
                }
              ],
              application_context: {
                brand_name: 'Maridela',
                shipping_preference: 'NO_SHIPPING',
                user_action: 'PAY_NOW'
              }
            });
          },
          onApprove: async (data: any, actions: any): Promise<any> => {
            const order = await actions.order.capture();
            await this.onPaymentSubmitted();
            console.log(order);
          },
          onError: (err: any) => {
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
      this.paypalButtonsRendered = true;
    },

    // onAuthorize (data, actions) {
    //   return actions.order.capture();
    // }

    async initComponent () {
      try {
        this.pendingComponentInit = true;
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        await this.$store.dispatch('purchase/setEditedPurchase');
        this.subscriptionTiers = _cloneDeep(this.tiers);
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    }
  }

});
