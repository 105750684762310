



















import Vue from 'vue';
import PurchaseComponent from '@/components/app/subscriptions/SubscriptionTiers.component.vue';

export default Vue.extend({

  name: 'SubscriptionTiers',

  components: {
    PurchaseComponent
  },

  data () {
    return {
      pendingComponentInit: false,
      purchaseStep: 1
    };
  },

  created () {
    this.initComponent();
  },

  methods: {

    // ================================================================================
    // Helpers
    // ================================================================================

    async initComponent () {
      try {
        this.pendingComponentInit = true;
        await this.$store.dispatch('subscription/tiers/fetchTiers');
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => { this.pendingComponentInit = false; }, 1000);
      }
    }
  }

});
